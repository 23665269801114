import React from 'react';
import { Link } from 'react-router-dom';
//importing typewriter-effect
import { TypeAnimation } from 'react-type-animation';

// import css 
import './Banner.scss'

const Banner = () => {
    return (
        <section className='banner' style={{
            backgroundImage: "url('/assets/img/restaurant/home/banner1.jpg')"
        }}>
            <div className="container">
                <div className="big_circle" style={{
                    backgroundImage: "url('/assets/img/restaurant/home/banner_circle_bg.webp')"
                }}>
                    <div className="bc_inner">
                        <h6>
                            experience the taste of india
                        </h6>
                        <h2>
                            Great dining
                        </h2>
                        <div className="typing_text">
                            <TypeAnimation
                                sequence={[
                                    // Same substring at the start will only be typed out once, initially
                                    'Restaurant',
                                    1500, // wait 1s before replacing "Mice" with "Hamsters"
                                    'Experience',
                                    1500
                                ]}
                                wrapper="h3"
                                speed={12}
                                repeat={Infinity}
                                cursor={null}

                            />
                        </div>
                        <Link className="theme_btn btn_center">
                            Our Menus
                        </Link>
                        <img src="assets/img/restaurant/home/banner_motion_img.webp" alt="" className="motion_img" />



                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner
