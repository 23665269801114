import React from 'react'

// import component 
import Banner from '../../components/banner/Banner'
import BitAbout from '../../components/bit-about/BitAbout'
import Services from '../../components/services/Services'
import Offer from '../../components/offer/Offer'
import Counter from '../../components/counter/Counter'
import Testimonial from '../../components/testimonial/Testimonial'
import FAQ from '../../components/faq/FAQ'
import OurTeam from '../../components/our-team/OurTeam'
import CopyRight from '../../components/copyright/CopyRight'

const Home = () => {
  return (
    <div className='home_pg'>
      <Banner />
      <BitAbout />
      <Services />
      <Offer />
      <Counter />
      <Testimonial />
      <FAQ />
      <OurTeam/>
      <CopyRight/>
    </div>
  )
}

export default Home
