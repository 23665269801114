import React from 'react'

// import css 
import './Offer.scss'

const Offer = () => {
  return (
    <section className='offer_banner'>
      <img src="/assets/img/restaurant/home/offer1.jpg" alt="" />
      <img src="/assets/img/restaurant/home/offer2.jpg" alt="" />
      <img src="/assets/img/restaurant/home/offer3.jpg" alt="" />
    </section>
  )
}

export default Offer
