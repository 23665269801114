import React from 'react'
import { Link } from 'react-router-dom'

// import css 
import './OurTeam.scss'

const OurTeam = () => {
    return (
        <section className="our_team">
            <div className="container top_cont">
                <div className="row">
                    <div className="col-md-9">
                        <div className="team_member">
                            <div className="tm_single">
                                <img src="/assets/img/restaurant/home/team2.jpeg" alt="" />
                                <div className="bottom">
                                    <h4>
                                       Khushi Shrivastav
                                    </h4>
                                    <h5>
                                       Wordpress Developer
                                    </h5>
                                </div>
                            </div>
                            <div className="tm_single">
                                <img src="/assets/img/restaurant/home/team1.jpeg" alt="" />
                                <div className="bottom">
                                    <h4>
                                        Naman Gaur
                                    </h4>
                                    <h5>
                                      React Developer
                                    </h5>
                                </div>
                            </div>
                            <div className="tm_single">
                                <img src="/assets/img/restaurant/home/team3.png" alt="" />
                                <div className="bottom">
                                    <h4>
                                       Sugandha Sahu
                                    </h4>
                                    <h5>
                                    Wordpress Developer
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="our_team_right">
                          <div className="otr_inner">
                          <h4 className="line_title">
                                <span></span>
                                our team
                            </h4>
                            <h6 className="main_desc">
                            Our Amazing Team, Discover the Talented Individuals Who Drive Our Success Story.
                            </h6>
                            <h2 className="main_title">
                                12+
                            </h2>
                          </div>
                            <Link className='theme_btn'>
                            Meet More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="social_medias">
                    <Link className="sm_single">
                        <img src="/assets/img/icons/footer_google.png" alt="" />
                    </Link>
                    <Link className="sm_single">
                        <img src="/assets/img/icons/footer_linkedin.png" alt="" />
                    </Link>
                    <Link className="sm_single">
                        <img src="/assets/img/icons/footer_instagram.png" alt="" />
                    </Link>
                    <Link className="sm_single">
                        <img src="/assets/img/icons/footer_facebook.png" alt="" />
                    </Link>
                    <Link className="sm_single">
                        <img src="/assets/img/icons/footer_snapchat.png " alt="" />
                    </Link>
                    <Link className="sm_single">
                        <img src="/assets/img/icons/footer_twitter.png" alt="" />
                    </Link>
                    <Link className="sm_single">
                        <img src="/assets/img/icons/footer_whatsapp.png" alt="" />
                    </Link>
                    <Link className="sm_single">
                        <img src="/assets/img/icons/footer_youtube.png" alt="" />
                    </Link>
                </div>
            </div>

        </section>
    )
}

export default OurTeam
