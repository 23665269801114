import React from 'react'
import { BrowserRouter } from "react-router-dom";

// import all templates 
import RestaurantRoutes from '../templates/restaurant/restaurant-routes/RestaurantRoutes';


const Router = () => {
    return (
        <BrowserRouter>
            <RestaurantRoutes />
        </BrowserRouter>
    )
}

export default Router
