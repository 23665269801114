import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'


// import css 
import './BitAbout.scss'

const BitAbout = () => {
  // add or remove class in every 5 sec 
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsActive((prev) => !prev); // Toggle isActive state
    }, 4000); // Interval of 5 seconds

    return () => clearInterval(interval); // Cleanup function to clear interval on component unmount
  }, []); // Empty dependency array ensures effect runs only once after component mount

  return (
    <section className={`bit_about ${isActive ? 'fivesec' : ''}`}>
      <div className="container">
        <div className="ba_inner">
          <h3 className="light_top_title">
            about us
          </h3>
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="about_left">
                <div className="img_container">
                  <img className="img1" src="/assets/img/restaurant/home/aboutimg1.jpg" alt="" />
                  <img className="img2" src="/assets/img/restaurant/home/aboutimg2.jpg" alt="" />
                </div>
                <div className="img_container">
                  <img className="img3" src="/assets/img/restaurant/home/aboutimg3.jpg" alt="" />
                  <img className="img4" src="/assets/img/restaurant/home/aboutimg4.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about_right">
                <h4 className="line_title">
                  <span></span>
                  Since 2012
                </h4>
                <h2 className="main_title">
                  Wonderful dining experience & food
                </h2>
                <h6 className="main_desc">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna minim veniam nostrud exercitation. Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna minim veniam nostrud exercitation.Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna minim veniam nostrud exercitation.Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna minim veniam nostrud exercitation.Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna minim veniam nostrud exercitation.
                </h6>
                <div className="bottom">
                  <Link to="" className='theme_btn'
                  >
                    Know More
                  </Link>
                 <Link to="" className='call_btn'>
                 <span class="material-symbols-outlined">
                    phone_forwarded
                  </span>
                  <span>
                    +91-87705-34650
                  </span>
                 </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>


  )
}

export default BitAbout
