import React from 'react'
import { Link } from 'react-router-dom'

// import css 
import './Services.scss'

const Services = () => {
    return (
        <section className="services">
            <div className="container">
                <div className="center_section_header text-center">
                    <h4 className="line_title justify-content-center"><span></span>CHOOSE Expertise</h4>
                    <h2 className="main_title">
                        Our services
                    </h2>
                </div>
                <div className="services_cards">
                    <Link className="sc_single">
                        <div className="left">
                            <img src="/assets/img/restaurant/home/service1.jpg" alt="" />
                        </div>
                        <div className="right">
                            <h5>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Est optio repudiandae fugit voluptatem veritatis quos, officia sit pariatur deleniti? Cupiditate quisquam accusantium id. Quis sapiente facere nobis assumenda expedita amet?
                            </h5>
                            <h6>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Est optio repudiandae fugit voluptatem veritatis quos, officia sit pariatur deleniti? Cupiditate quisquam accusantium id. Quis sapiente facere nobis assumenda expedita amet?
                            </h6>
                            <Link className="detail theme_btn">
                                Detail
                            </Link>

                        </div>
                    </Link>
                    <Link className="sc_single">
                        <div className="left">
                            <img src="/assets/img/restaurant/home/service2.jpg" alt="" />
                        </div>
                        <div className="right">
                            <h5>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Est optio repudiandae fugit voluptatem veritatis quos, officia sit pariatur deleniti? Cupiditate quisquam accusantium id. Quis sapiente facere nobis assumenda expedita amet?
                            </h5>
                            <h6>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Est optio repudiandae fugit voluptatem veritatis quos, officia sit pariatur deleniti? Cupiditate quisquam accusantium id. Quis sapiente facere nobis assumenda expedita amet?
                            </h6>
                            <Link className="detail theme_btn">
                                Detail
                            </Link>

                        </div>
                    </Link>
                    <Link className="sc_single">
                        <div className="left">
                            <img src="/assets/img/restaurant/home/service3.jpg" alt="" />
                        </div>
                        <div className="right">
                            <h5>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Est optio repudiandae fugit voluptatem veritatis quos, officia sit pariatur deleniti? Cupiditate quisquam accusantium id. Quis sapiente facere nobis assumenda expedita amet?
                            </h5>
                            <h6>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Est optio repudiandae fugit voluptatem veritatis quos, officia sit pariatur deleniti? Cupiditate quisquam accusantium id. Quis sapiente facere nobis assumenda expedita amet?
                            </h6>
                            <Link className="detail theme_btn">
                                Detail
                            </Link>

                        </div>
                    </Link>
                    <Link className="sc_single">
                        <div className="left">
                            <img src="/assets/img/restaurant/home/service4.jpg" alt="" />
                        </div>
                        <div className="right">
                            <h5>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Est optio repudiandae fugit voluptatem veritatis quos, officia sit pariatur deleniti? Cupiditate quisquam accusantium id. Quis sapiente facere nobis assumenda expedita amet?
                            </h5>
                            <h6>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Est optio repudiandae fugit voluptatem veritatis quos, officia sit pariatur deleniti? Cupiditate quisquam accusantium id. Quis sapiente facere nobis assumenda expedita amet?
                            </h6>
                            <Link className="detail theme_btn">
                                Detail
                            </Link>

                        </div>
                    </Link>
                   
                </div>
            </div>
        </section>
    )
}

export default Services
