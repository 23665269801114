import React from 'react'
import { Route, Routes } from "react-router-dom";

// import css 

import '../common-css/Font.css'
import '../common-css/Color.css'
import '../common-css/Style.scss'


// import pages 
import Header from '../components/header/Header';
import Home from '../pages/home/Home'

const RestaurantRoutes = () => {
    return (
        <>
        <Header/>
        <Routes>
            <Route path="/" element={<Home />}></Route>
        </Routes>
        </>

    )
}

export default RestaurantRoutes
